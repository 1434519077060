@import '../../variable';
.order-amount{
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;

  .amount{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    color: white;
  }
}

.coffee-page {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .modal-body {
    max-width: 800px !important;
    max-height: 1200px !important;
  }

  .menu-login {
    .modal-body {
      height: unset;
      width: unset;
      padding: 0;
      background-color: $primary-background-color;
      text-align: center;

      > svg {
        color: black;
        border-color: black;
      }

      .message {
        padding: 35px;

        .item-name {
          font-weight: bold;
        }
      }
    }
  }

  //@media screen and (min-width: $lg-screen) {
  //  .list-container > .detail-container:not(:last-child) {
  //    padding-bottom: 20px !important;
  //    margin-bottom: 20px !important;
  //  }
  //
  //  .coffee-container.coffee-container-expand {
  //    height: calc(100% - 100px) !important;
  //  }
  //
  //  .invalid-min-order {
  //    padding: 20px 15px !important;
  //  }
  //  .image-container {
  //    width: 100px !important;
  //    height: 100px !important;
  //    min-width: 100px !important;
  //    min-height: 100px !important;
  //  }
  //
  //  .side-bar {
  //    display: flex;
  //    flex-direction: column;
  //    justify-content: center;
  //
  //    .selected::before {
  //      width: 5px !important;
  //    }
  //
  //    > div {
  //      width: calc(100% - 40px);
  //    }
  //  }
  //
  //  .other-request {
  //    input {
  //      font-size: 1rem;
  //    }
  //  }
  //
  //  .coffee-container {
  //    .side-bar {
  //      max-width: 250px !important;
  //
  //      > div {
  //        padding: 20px !important;
  //      }
  //    }
  //
  //    .list-container {
  //      .header {
  //        padding: 20px !important;
  //      }
  //    }
  //
  //    .title-en {
  //      padding-right: 80px !important;
  //    }
  //
  //    .title-cn {
  //      padding-right: 80px !important;
  //    }
  //  }
  //
  //  .list-container .detail-container .detail {
  //    max-width: calc(100% - 210px) !important;
  //  }
  //
  //  .footer-btn-list {
  //    padding: 20px 10px !important;
  //  }
  //
  //  .font-weight-light {
  //    font-weight: 300;
  //  }
  //
  //  .checkout-footer.expand {
  //    height: 100px !important;
  //    line-height: 100px !important;
  //  }
  //}

  .lds-facebook {
    display: flex;
    position: relative;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;

    div {
      width: 8px;
      animation: lds-facebook 2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    div:nth-child(1) {
      animation-delay: -0.6s;
    }

    div:nth-child(2) {
      margin: 0 5px;
      animation-delay: -0.3s;
    }
  }

  @keyframes lds-facebook {
    0%,
    100% {
      height: 20px;
      background: #3c3c3c;
    }
    80% {
      height: 5px;
      background: #e3e3e3;
    }
  }

  .scan-qr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .coffee-container.coffee-container-expand {
    height: calc(100% - 50px);
  }

  .coffee-container {
    display: flex;
    overflow: hidden;
    height: 100%;
    flex: 1;
    transition: height 0.5s;
    z-index: 2;

    .badge {
      background: #e88b00;
      color: white;
      position: absolute;
      font-size: 0.7rem;
      padding: 1px 3px;
    }

    .side-bar {
      width: 25%;
      max-width: 150px;
      background: #efefef;
      font-size: 0.9rem;
      text-align: center;
      overflow: scroll;

      > div {
        padding: 15px 8px;
        color: grey;
        border-bottom: 0.5px solid #e6e5e5;
        cursor: pointer;
        position: relative;
      }

      .badge-right {
        right: 0;
        top: 0;
      }

      .selected {
        font-weight: bold;
        //background: whitesmoke;
        background: white;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          content: '';
          bottom: 0;
          top: 0;
          width: 2px;
          background: $color-primary;
        }
      }
    }

    .coffee-section{
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
    }

    .coffee-list {
      padding-left: 15px;
      padding-bottom: 15px;
      flex: 1;
      overflow: auto;

      .list-container {
        .header {
          position: sticky;
          padding: 15px 0;
          font-weight: bold;
          top: 0;
          background: white;
          color: #797979;
          z-index: 2;
          font-size: 0.9rem;
        }

        > .detail-container:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .detail-overlay {
          .image-container,
          .detail {
            opacity: 0.3;
          }
        }

        .detail-container {
          display: flex;
          position: relative;

          .image-container {
            width: 70px;
            min-width: 70px;
            height: 70px;
            min-height: 70px;
            border-radius: 3px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
              height: 97%;
              object-fit: cover;
              width: 100%;
            }

            .badge-right {
              right: -4px;
              bottom: 4px;
              border-radius: 2px;
              max-width: 70%;
            }
          }

          .text {
            color: white;
            font-weight: 600;
            font-size: 0.9rem;
            background: $color-primary;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 4px;
            max-width: 90%;
            white-space: nowrap;
            padding: 5px 15px;
            text-align: center;
          }

          .detail {
            position: relative;
            margin-left: 10px;
            flex: 1;
            max-width: calc(100% - 80px);

            .title-en {
              text-overflow: ellipsis;
              display: -webkit-box;
              line-height: 1.3rem;
              max-height: 2.6rem;
              -webkit-line-clamp: 2;
              padding-right: 20px;
              overflow: hidden;
              /* autoprefixer: off */
              -webkit-box-orient: vertical;
            }

            .title-cn {
              color: #797979;
              font-size: 0.8rem;
              font-weight: 300;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: 20px;
            }

            .price {
              position: absolute;
              left: 0;
              bottom: 0;
              font-size: 1.2rem;
              font-weight: bold;
              white-space: nowrap;

              .normal-price{
                text-decoration: line-through;
                font-weight:normal;
                font-size:xx-small;
              }
            }

            .fa-plus {
              position: absolute;
              background: $color-primary;
              border-radius: 50%;
              padding: 0.4rem 0.42rem;
              color: white;
              font-size: 0.7rem;
              bottom: 0;
              right: 10px;
            }
          }
        }
      }
    }
  }

  .checkout-footer {
    background-color: $color-primary;
    transition: 0.5s;
    text-align: center;
    font-weight: bold;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 0;
    overflow: hidden;
    height: 0;
    border-top: 1px solid #e3e3e3;

    span {
      font-size: 1.2rem;
    }

    .fa-chevron-right {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.5rem;
    }
  }

  .checkout-footer.expand {
    line-height: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 640px){
  .screen-over-flow{
    overflow:auto;
    height:80px;
  }
}
