@import '../../variable';

.button-component {
  align-items: center;
  border: 0.5px solid $color-primary;
  color: white;
  display: flex;
  font-weight: 600;
  height: 33px;
  min-width: 35%;
  padding: 8px;
  text-align: center;

  @media screen and (min-width: $lg-screen) {
    height: unset;
    padding: 16px;
  }

  &:active {
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
  }

  > div {
    margin: auto;
  }
}

.button-component.primary-color {
  background-color: $color-primary;
}

.button-component.sm-btn {
  height: unset;
}

.button-component.bg-btn {
  @media screen and (min-width: $lg-screen) {
    min-height: 63px;
  }
}

.button-component.rounded {
  border-radius: 8px;
}
