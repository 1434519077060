@import '../../variable';

.global-page {
  .footer {
    display: flex;
    background: $dark-blue-background-color;

    .aliments-logo {
      //fill: #ffffff; //default color

      // To looks similar to fontAwesome settings
      height: 1em;
      vertical-align: -0.125em;
    }

    .active {
      color: $color-primary !important;
    }

    > .icon-container {
      text-align: center;
      flex: 1;
      margin: 15px 5px 12px;
      color: white;
      transition: 0.2s;
      position: relative;

      .cart-no {
        position: absolute;
        top: -8px;
        right: 8px;
        border-radius: 50%;
        background: red;
        width: 15px;
        height: 15px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 0.7rem;
          vertical-align: middle;
          font-weight: bold;
        }
      }

      svg {
        font-size: 1.3rem;
      }

      > div {
        font-size: 0.8rem;
        font-weight: 600;
        margin-top: 2px;
      }
    }
  }
}
