.wechat-blocker {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  display: flex;
  flex-direction: column;

  .img-container {
    position: absolute;
    width: 100%;
    text-align: right;

    img {
      width: 50vw;
      margin-right: 20px;
    }
  }

  .message {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2rem;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
    }

    img {
      margin-left: 20px;
    }
  }
}
