@import '../../variable';

.tab-btn{
  background-color: white;
  border: 2px solid $color-primary;
  text-align: center;
  padding: 5px;
  color: $color-primary;
}

.tab-img{
  text-align: center;
  width: 25px;
  height: 25px;
  align-self:center;
}