@import '../../variable';

$center-flex: 2;

.landing-page {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 16px;

  color: white;

  .center {
    align-content: center;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;

    .logo {
      height: 150px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-y: center;
      background-position-x: center;
      margin-bottom: 8px;
    }

    flex: 2;

    vertical-align: center;
  }

  .bottom {
    display: flex;
    flex: 0;

    .powered-by {
      a:visited,
      a:active,
      a:link {
        text-decoration: none;
        color: white;
      }

      align-self: flex-end;
      width: 100%;
      text-align: center;
      font-size: 0.8rem;
    }
  }

  .sm-font {
    font-size: 0.8em;
  }

  //
  .title-bar {
    display: flex;
    flex: none;
    text-align: center;
    padding: 20px;
    background-color: $item-background-color;
    height: 150px;

    > * {
      flex: 1;
    }

    > .back-button {
      text-align: left;
    }
  }
}

.img-merchant{
  //border: 1px solid black;
  border-radius: 22px;
  box-shadow: 0 20px 20px -10px gray;
  height: 150px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}