@import '../../variable';
$primary-color: #f8b601; //#88AFD6;

.merchant-card {
  display: flex;
  margin: 2.0rem;
  align-items: center;

  .img-merchant{
    //border: 1px solid black;
    border-radius: 22px;
    box-shadow: 0 20px 20px -10px gray;
    width: 100px;
    height: 100px;
  }

  .merchant-description{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    align-self: center;


    .title {
      font-weight: bold;
      color: black;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .category {
      margin-bottom: 16px;
      font-size: 12px;
      color: #546E7A;
    }

    .location {
      display:flex;
      font-size: 12px;
      color: #546E7A;
      margin-bottom: 8px;
      align-items: center;

      img{
        width: 9.33px;
        height: 11.67px;
        margin-right: 6.67px;
      }
      i {
        font-size: 14px;
      }
    }

    .tag {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #546E7A;

      div {
        margin-right: 5px;
        margin-bottom: 5px;
        align-self: center;
        padding: 5px 10px;
        background-color: #93B352;
        border-radius: 6px;
        backdrop-Filter: blur(4px);
        color: white;
        font-weight: bold;
      }
    }
  }

  .text{
    position: absolute;
    left: 32%;
    top: 35%;
    padding: 5px 15px;
    text-align: center;
    background-color: rgba(84,110,122, 0.8);
    color:white;
    border-radius: 4px;
    font-weight: 100;
    font-size: 0.9rem;
    max-width: 90%;
    min-width: 50%;
  }
}

.outerHeader{
  width: inherit;
}

.backgroundFilter{
  //position:relative;

  .img-merchant{
    opacity: 0.3;
  }
}