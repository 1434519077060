.login-dialog {
  background-color: whitesmoke;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;
  max-width: 260px;

  .btn {
    cursor: pointer;
    font-size: 20px;
    color: #3b5998;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    margin: 5px;

    img {
      width: 20px;
    }
  }
}

@media screen and (max-height: 620px){
  .login-dialog{
    padding-top: 3rem;
    padding-bottom: 0;
  }
}
