@import '../../variable';

.operating-hour-modal {
  .modal-expand,
  .modal-overlay {
    z-index: 9999;
  }

  .modal-body {
    height: unset;
    padding: 0;
    width: 100%;

    .fa-times {
      color: #3c3c3c;
      border-color: #3c3c3c;
    }
  }

  .name {
    font-size: 1.5rem;
  }

  .subtitle {
    font-weight: 300;
    font-size: 0.8rem;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .body {
    padding: 20px 15px;

    table {
      border-spacing: 4px;

      td:not(:last-child) {
        text-align: right;
      }
    }
  }

  .btn-confirm {
    background-color: $color-primary;
    color: white;
    padding: 12px;
    border: none;
  }
}
