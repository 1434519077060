.right-arrow-button {
  .fa-chevron-right {
    transition: 0.4s;
    transform: rotate(0);
  }

  .rotate-90-clockwise {
    transform: rotate(90deg);
  }
}
