.about-page {
  color: white;
  background: black;
  position: relative;
  text-align: center;
  padding-top: 20px;
  background-image: url('/image/about-us.png');
  background-size: cover;
  background-position: center;

  .mb {
    margin-bottom: 10%;
  }

  .title {
    font-size: 4rem;

    img {
      max-width: 500px;
      width: 50%;
    }
  }

  .version {
    font-size: 0.8rem;
    font-weight: 300;
    color: #e3e3e3;
  }

  .contain {
    font-weight: 300;
    color: #e3e3e3;
    width: 80%;
    max-width: 400px;
    margin: auto;

    > div:not(:last-child) {
      margin-bottom: 10%;
    }
  }

  .action {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    width: 90%;
    margin: auto;
    max-width: 400px;
    font-weight: bold;
    font-size: 0.9rem;

    .download-app {
      font-weight: normal;
      font-size: unset;

      img {
        margin-top: 10px;
        width: 70%;
        padding-bottom: 20px;
      }
    }

    .action-row {
      padding: 10px 0;
      text-align: center;
      white-space: nowrap;

      .middle {
        margin: 0 20px;
      }

      a {
        color: inherit;
      }

      .btn {
        display: inline-block;
      }

      .btn-first {
        width: calc(60% - 15px);
        text-align: right;
        margin-right: 15px;
      }

      .btn-last {
        width: 40%;
        text-align: left;
      }
    }

    .border {
      position: relative;
      border-bottom: 0.5px solid #e3e3e3;
    }

    .action-row.border {
      display: flex;
      justify-content: center;
    }
  }

  .contact-us-modal {
    .fa-times {
      background: black;
    }

    .modal-body {
      height: unset;
      padding: 0;
      color: #3c3c3c;
      text-align: left;

      .container {
        padding: 30px 15px;

        > div:not(:last-child) {
          margin-bottom: 20px;
        }

        .title {
          font-size: 1.3rem;
          text-align: center;
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #e3e3e3;
          padding: 5px;
          width: calc(100% - 10px);
          outline: none;
        }

        textarea {
          resize: none;
          border: 1px solid #e3e3e3 !important;
          margin-top: 5px;
        }

        select {
          background: none;
          border-radius: 0;
          appearance: none;
        }
      }
    }

    .disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}
