.popup-enter {
  opacity: 0.8;
  transform: scale(0.1);
}

.popup-enter.popup-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 150ms cubic-bezier(0, 0.8, 0, 1.2);
}

.popup-leave {
  opacity: 1;
  transform: scale(1);
}

.popup-leave.popup-leave-active {
  opacity: 0.01;
  transform: scale(0);
  transition: 100ms ease-in-out;
}

.modal-expand {
  display: block !important;
}

.modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  padding: 30px 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 99;
}

.modal-body-static-height {
  min-height: 500px;
}

.modal-body {
  border-radius: 5px;
  overflow: hidden;
  background: white;
  height: calc(100% - 20px);
  padding: 10px;
  width: calc(100% - 20px);
  position: relative;
  max-height: 600px;
  max-width: 500px;
  margin: auto;
  transition: min-height 0.1s;

  .fa-times {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 0.8rem;
    border-radius: 50%;
    padding: 5px 7px;
    border: 0.5px solid white;
    color: white;
    z-index: 999;
  }
}
