@import '../../variable';

.order-item-container {
  @media screen and (min-width: $lg-screen) {
    height: 55px;
  }

  font-size: $normal-font-size;
  border-bottom: 1px $primary-background-color solid;
  background-color: $item-background-color;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  .item-container {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 5px 0 10px;

    .item-name-container {
      display: flex;
      position: relative;

      .item-name {
        font-weight: bold;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
      }

      .item-badge {
        flex: none;
        height: 10px;
        padding: 0 7px;
        margin: 0 5px;
        border-radius: 15px;
        font-size: $small-font-size;
        background-color: orange;
      }
    }

    .item-desc {
      font-size: 0.6rem;
      font-weight: 300;
    }
  }

  .price-box-container {
    flex: none;
    text-align: right;
    font-size: $normal-font-size;
    font-weight: bold;
  }

  .price-container {
    padding: 0 5px;
    text-align: right;

    .add-on-price {
      font-size: 0.4rem;
    }
  }
}
