@import '../../variable';

.quantity-box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;

  .quantity {
    font-size: $normal-font-size;
    width: 25px;
    text-align: center;
  }
}
