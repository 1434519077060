@import '../../variable';

.app-bar-component {
  display: flex;
  flex: none;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;

  .back-button{
    text-align: left;
  }

  .app-bar-left {
    flex: 1 1;
  }

  .app-bar-center {
    flex: 1 1;
  }

  .app-bar-right {
    flex: 1 1;
  }
}
