#einvoice-wrapper {
    background-color: #edf2f7;
}

.receipt {
    background: linear-gradient(135deg, transparent 5.68px, white 5.69px) top left,
        linear-gradient(45deg, white 2.8px, transparent 2.81px) top left,
    linear-gradient(135deg, white 2.8px, transparent 2.81px) bottom left,
    linear-gradient(45deg, transparent 5.68px, white 5.69px) bottom left;
    background-repeat: repeat-x;
    background-size: 8px 4px;
    padding: 4px 0;
}

.receipt {
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.1));
    margin: 0 auto;
}

#einvoice-wrapper .modal-body {
    height: fit-content;
}

label.required::after {
    content: " *";
    color: red;
}

.react-tel-input .form-control {
    border-radius: 0;
    border-width: 0;
    border-bottom-width: 2px;
    border-color: #e2e8f0;
    font-size: 16px !important;
    width: 100% !important;
    height: 25px;
    padding-left: 40px;
}

.react-tel-input .form-control:focus, .react-tel-input .form-control:focus + .flag-dropdown {
    --border-opacity: 1;
    border-color: #ecc94b !important;
}

.react-tel-input .flag-dropdown {
    background: #ffffff;
    border: 2px solid !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-radius: 0 !important;;
    border-color: #e2e8f0 !important;

}
