@import '../../variable';

// Make smaller modal
.promotion {
  height: 435px !important;
  width: 250px !important;
  padding: 0;
}

.promotion-dialog {
  font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;

  .promotion-picture {
    flex: 1;

    //padding-bottom: 40%;
    background-size: 100%;
    //background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  .promotion-message {
    padding: 10px 20px 10px !important;

    flex: none;

    .promotion-title {
      font-size: 1.3em;
      font-weight: bold;
      padding-bottom: 12px;
      //line-height: 36px;
    }
  }

  .promotion-button {
    flex: none;
    padding: 10px 20px 10px !important;
    display: flex;
    justify-content: center;

    .btn {
      padding: 8px 5px;
      min-width: 35%;
      text-align: center;
      border: 0.5px solid $color-primary;
      margin-left: 10px;
      border-radius: 4px;
    }

    .btn-submit {
      background: $color-primary;
      color: white;
    }

    .btn-cancel {
      color: $color-primary;
    }
  }
}
