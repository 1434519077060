.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  flex-direction: column;
  opacity: 0.5;
  z-index: 999;
  touch-action: none;
  pointer-events: none;

  .lds-facebook {
    width: 30px;
    min-height: 20px;
    align-items: center;
    justify-content: space-between;
  }

  svg {
    font-size: 3rem;
  }
}
