@import '../../variable';

.nearby-merchant-page {
  padding-top: 47px;

  .fa-times {
    background: rgba(115, 115, 115, 0.3);
  }

  .font-weight-bold {
    font-weight: bold;
  }

  .closing-container {
    position: absolute;
    left: 50%;
    width: 70%;
    max-width: 400px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    font-weight: 600;
    font-size: 1.2rem;
  }

  .merchant-title {
    color: $color-primary;
    font-weight: bold;
    font-size: 1.1rem;
    display: flex;
    align-items: center;

    > :first-child {
      flex: 1;
    }
  }

  .merchant-category {
    margin-right: 5px;
  }

  .dollar-highlight {
    .fa-dollar-sign {
      color: $color-primary !important;
    }
  }

  .fa-dollar-sign {
    font-size: 0.8rem;
    margin-right: 1.5px;
  }

  .title {
    text-align: center;
    font-weight: bold;
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: $dark-blue-background-color;
    z-index: 2;
    background: white;
  }

  .merchant-details {
    > div:not(:last-child) {
      margin-bottom: 10px;
    }

    .merchant-image {
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding-bottom: 60%;
      position: relative;
    }

    .merchant-row {
      padding: 10px 15px;
      position: relative;

      > div:not(:last-child) {
        margin-bottom: 2px;
      }

      .font-sm {
        font-size: 0.8rem;
        font-weight: 300;
      }

      .fa-info {
        position: absolute;
        bottom: 12px;
        right: 15px;
        border-radius: 50%;
        padding: 3px 6px;
        font-size: 0.6rem;
        border: 1px solid;
        color: $color-primary;
      }
    }
  }

  .coffee-modal {
    > :first-child {
      flex: 1;
      overflow: auto;
    }

    .detail {
      left: 0 !important;
      right: 0;
      padding: 10px 15px;
      bottom: 0 !important;
      background: rgba(0, 0, 0, 0.6);
    }

    .cover-image-container {
      padding-bottom: 60%;
    }

    .detail-container {
      padding: 20px 15px;

      a {
        color: inherit;
        text-decoration: none;
      }

      .detail-flex {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        > a,
        > div {
          flex: 1;
          text-align: center;
          font-weight: 300;

          .sm-text {
            font-size: 0.7rem;
          }

          .square {
            border-radius: 50%;
            position: relative;
            background: $color-primary;
            margin: 0 auto 2px;
            width: 35px;
            height: 35px;

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 1.2rem;
              color: white;
            }
          }
        }
      }

      .detail-row {
        border-bottom: 1px solid #e3e3e3bf;
        padding-bottom: 12px;
        position: relative;
        padding-left: 40px;
        margin-bottom: 12px;
        font-weight: 300;

        .operating-hour-row {
          display: flex;
          align-items: center;

          svg {
            padding: 0 5px;
            font-size: 4px;
            margin-top: 3px;
          }
        }

        > svg {
          position: absolute;
          left: 5px;
          top: calc(50% - 5px);
          transform: translate(0, -50%);
          font-size: 1.2rem;
          color: $color-primary;
        }
      }
    }

    .distance-text {
      margin-bottom: 2px;
      padding-left: 5px;
      font-size: 1rem;
      color: $color-primary;
    }

    .merchant-detail-flex {
      display: flex;
      align-items: center;
      font-weight: 300;
      word-break: break-all;
      font-size: 0.8rem;

      .merchant-detail-flex {
        flex: 1;
      }

      .fa-circle {
        font-size: 10px;
        transform: scale(0.5);
        padding: 0 5px;
      }
    }

    .btn-disabled {
      > .btn.btn-submit {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      padding: 0 15px 15px;

      > :first-child {
        margin-right: 10px;
      }

      > :last-child {
        margin-left: 10px;
      }

      .btn.btn-submit {
        background: $color-primary;
        color: white;
        text-align: center;
        padding: 10px 20px;
        flex: 1;
        border-radius: 4px;
        font-weight: 600;
      }
    }
  }
}
