@import "../../../variable";

.link:hover{
  background-color: $color-primary;
  color: white;
  border-radius: 8px;
}

.selected-time{
  background-color: gold;
  color: white;
  border-radius: 8px;
}

.react-tabs {
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%;

  .react-tabs__tab-list {
    border: none;
    margin: 0;

    .react-tabs__tab {
      border: none;
      padding: 10px 0;
      width: 33%;
      text-align: center;
      position: relative;
      opacity: 0.3;

      &:before {
        content: '';
        opacity: 0.6;
        transform: scale(0);
        transform-origin: 50% 50%;
      }
    }

    .react-tabs__tab--selected {
      position: relative;
      opacity: 1;

      &:before {
        position: absolute;
        left: 0;
        right: 0;
        height: 3px;
        background: $color-primary;
        bottom: 0;
        transition: 0.2s;
        transform: scale(1);
      }
    }
  }
}