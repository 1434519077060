@import '../../variable';

.checkout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background-color: $primary-background-color;

  .confirm-pay-modal {
    text-align: center;

    .modal-expand {
      z-index: 999;
    }

    .container {
      padding: 20px;
    }

    .title {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .body > div:not(:last-child) {
      margin-bottom: 15px;
    }

    .footer {
      display: flex;
      border-top: 1px solid #e3e3e3;

      .btn {
        flex: 1;
        padding: 10px 15px;
        text-align: center;
      }

      .btn-primary {
        background: $color-primary;
      }
    }
  }

  .qrcode-modal {
    text-align: center;

    .modal-body {
      background: white;
      padding: 20px;

      > *:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .title {
      font-weight: 600;
      font-size: 1.3rem;
      margin-bottom: 5px;
    }

    img {
      width: 100%;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #e3e3e3;
    }

    .btn-primary {
      padding: 15px;
      background: $color-primary;
      margin: auto -20px -20px;
      font-weight: 600;
    }
  }

  .img-wechat {
    background-color: #19c800;
    border-radius: 5px;
  }

  .maximum-order-modal {
    .modal-content {
      padding: 30px 30px 20px;
    }

    .font-weight-bold {
      font-weight: bold;
    }
  }

  .item-list-container {
    flex: 1;
    //overflow-y: scroll;
    overflow-y: auto;
  }

  .modal-body:not(.coffee-modal-body) {
    height: unset;
    width: unset;
    padding: 0;
    background-color: $primary-background-color;
    text-align: center;

    > svg {
      color: black;
      border-color: black;
    }

    .message {
      padding: 35px;

      .item-name {
        font-weight: bold;
      }
    }
  }
}

.circle {
  $circlePx: 16px;

  @media screen and (min-width: $lg-screen) {
    width: 32px;
    height: 32px;
  }

  padding: 0;
  margin: 0;
  width: $circlePx;
  height: $circlePx;
  border-radius: 100%;
  border: $color-primary 1px solid;
  font-size: 0.6rem;

  // Make logo in center
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;

  &:first-child {
    flex: 1;
  }

  &:last-child {
    flex: none;
  }
}

.action-container {
  background-color: $item-background-color;
  font-size: $action-container-font-size;

  img {
    font-size: unset;
    color: red;
    //margin: 0 10px;
    margin-left: 10px;
    height: 25px;
  }

  .hide-button {
    text-align: right;

    .fa-chevron-up {
      transition: 0.4s;
      padding: 5px 15px;
      transform: rotate(0);
    }

    .fa-rotate-up {
      transform: rotate(180deg);
    }
  }

  .pay-with-partner,
  .coupon-container,
  .method-container,
  .payment-method {
    height: 0;
    overflow: hidden;
    transition: 0.2s;
    border: none;
  }

  .expand {
    border-bottom: $primary-background-color 1px solid !important;
  }

  .expand,
  .total-container {
    height: 40px;
  }

  .payment-method-blur {
    opacity: 0.2;
  }

  .payment-method {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    position: relative;

    img[alt='credit-card'] {
      margin-right: 10px;
    }

    .loading-overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 1.3rem;
        color: white;
      }
    }
  }

  .braintree-container,
  .price-detail-container {
    padding: 10px 15px;
  }

  > * {
    border-bottom: $primary-background-color 1px solid;
  }

  > :last-child {
    border-bottom: none;
  }

  .method-container {
    justify-content: space-between;

    > :first-child {
      border-right: 1px $primary-background-color solid;
    }

    .dine-in,
    .takeaway {
      flex: 1;
      text-align: center;
      font-size: 1.2rem;
      opacity: 0.2;

      display: flex;
      justify-content: center;
      align-items: center;

      &.selected {
        opacity: 1;
        font-weight: 500;
      }
    }
  }

  .coupon-container {
    .coupon-code {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-right: 1px $primary-background-color solid;

      input {
        padding: 0;
        margin: 0;
        text-align: center;
        background-color: $item-background-color;
        border: none;
        color: #5a5a5a;
        outline: none;

        &::placeholder {
          color: #a5a5a5;
        }
      }
    }
  }

  .order-button-blur {
    opacity: 0.5;
    pointer-events: none;
  }

  .order-button,
  .apply-button {
    text-align: center;
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .boost {
    background-color: $boost-color;
  }

  .wechat-pay {
    background-color: $wechat-color;
  }

  .touchngo,
  .ipay88_tng {
    background-color: $tng-color;
  }

  .grab-pay,
  .ipay88_grabpay {
    background-color: $grab-color;
  }

  .order-button {
    width: 50%;
    height: 100%;
    color: #ffffff;

    &.default {
      background-color: $aliment-orange;
    }

    > img {
      margin: 0;
      padding: 0;
    }

    .message {
      &.padding-left {
        padding-left: 5px;
      }

      &.padding-right {
        padding-right: 5px;
      }

      //font-size: 3.5vw;
    }
  }

  .apply-button {
    width: 35%;
    pointer-events: none;
    opacity: 0.2;
  }

  .apply-button.valid {
    opacity: 1;
    pointer-events: all;
  }

  .redeem,
  .total {
    text-align: center;
  }

  .total-container {
    padding: 0;

    .total {
      flex: 1;
    }
  }
}

.primary-color {
  color: $color-primary;
}

.primary-bg {
  background-color: $color-primary;
}

.large-text {
  font-size: $large-font-size;
}

.coupon-container,
.method-container,
.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pay-with-partner {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}

.button {
  color: #ffffff;
  background-color: $color-primary;
  padding: 10px;
  border: 10px;
  border-radius: 10px;
  text-align: center;
}

.braintree-container-hide {
  .modal,
  .modal-body,
  .modal-overlay {
    height: 0 !important;
    overflow: hidden;
  }

  * {
    pointer-events: none;
  }
}

.braintree-container {
  .modal-body {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .braintree-heading {
    font-weight: 600;
    font-size: 1.1rem;
  }

  .braintree-upper-container::before,
  .braintree-method,
  .braintree-sheet {
    background-color: transparent;
  }

  #dropin-container {
    padding: 10px 15px 0;
    overflow: auto;
    flex: 1;
  }

  .braintree-methods-initial {
    margin-bottom: 0;
  }

  .braintree-method {
    width: 100% !important;
    outline: none;
    border: none;
    border-radius: 0 !important;
    padding: 10px;
    margin: 0 !important;
    border-bottom: 1px solid #e3e3e3 !important;
  }

  .braintree-method__icon.braintree-method__check {
    transform: scale(0.6) !important;
  }
}

.payment-method-container {
  .modal-body {
    width: 100%;

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .title {
        font-weight: bold;
        border-bottom: 1px solid #e3e3e3;
        padding: 10px;
        font-size: 1.2rem;
      }

      .body {
        padding: 10px 20px;

        > div > div:not(:last-child) {
        }

        .payment-disabled {
          opacity: 0.6;
          pointer-events: none;
        }

        .payment-row {
          text-align: left;
          font-weight: 300;
          position: relative;
          padding: 12px 0 12px 45px;

          .pts {
            margin-left: 8px;
            font-size: 0.9rem;
          }

          img {
            position: absolute;
            height: 25px;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            margin-right: 10px;
          }
        }
      }
    }
  }
}
