@import './variable';

.text-primary {
  color: $color-primary;
}

.bg-primary {
  background-color: $color-primary;
}

html,
body {
  font-size: $body-font-size;
  height: 100%;
  width: 100%;
  max-width: 500px;
  margin: auto;
  overflow: hidden;
  position: relative;
  background: white;
}

.main {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;

  > :not(.global-page) {
    flex: 1;
    overflow: auto;
  }
}

.smooth-touch {
  -webkit-overflow-scrolling: touch;
}

.disable-scroll {
  overflow: hidden !important;
}

.confirmation-button-container {
  display: flex;
  justify-content: space-between;
  text-align: center;

  .cancel-button,
  .confirm-button {
    padding: 10px;
    flex: 1;
  }

  .confirm-button {
    color: #ffffff;
    background-color: $color-primary;
  }
}

.verify-card-modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0 20px;

  .body {
    flex: 1;
    max-width: 600px;
    background: white;
    margin: 0 auto;

    > iframe {
      width: 100%;
    }
  }
}

.coffee-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -10px;
  height: calc(100% + 20px);

  .cover-image-container {
    padding-bottom: 40%;
    background-size: 100%;
    background-position: center;
    position: relative;

    .detail {
      position: absolute;
      bottom: 10px;
      color: white;
      left: 15px;
      z-index: 2;

      .title {
        font-weight: bold;
        font-size: 1.3rem;
      }
    }
  }
}

.app-loader {
  width: 32px;
  height: 32px;
  border: 3px solid #e1e1e1;
  border-bottom-color: #f8b601;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cloud-uploading {
  width: 175px;
  height: 80px;
  display: block;
  background-image: linear-gradient(#263238 50px, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), radial-gradient(circle 50px at 50px 50px, #FFF 100%, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), linear-gradient(#FFF 50px, transparent 0);
  background-size: 64px 6px, 50px 50px, 100px 76px, 50px 50px, 120px 40px;
  background-position: 55px 60px, 0px 30px, 37px 0px, 122px 30px, 25px 40px;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
}

.cloud-uploading::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(-180deg);
  top: 62px;
  height: 64px;
  width: 60px;
  background-color: #FFF;
  background-image: linear-gradient(#DDD 20px, transparent 0), linear-gradient(#DDD 5px, transparent 0), linear-gradient(#DDD 10px, transparent 0), linear-gradient(#DDD 10px, transparent 0);
  background-size: 50px 20px;
  background-position: 5px 36px, 5px 25px, 5px 10px;
  background-repeat: no-repeat;
  border-radius: 2px 2px 4px 4px;
  z-index: 10;
  box-shadow: 0px -4px 7px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  animation: animloader 4s linear infinite;
}

@keyframes animloader {
  0% {
    height: 64px;
  }
  90%, 100% {
    height: 0px;
  }
}

.cloud-loader {
  width: 175px;
  height: 80px;
  display: block;
  background-image: radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), radial-gradient(circle 50px at 50px 50px, #FFF 100%, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), linear-gradient(#FFF 50px, transparent 0);
  background-size: 50px 50px, 100px 76px, 50px 50px, 120px 40px;
  background-position: 0px 30px, 37px 0px, 122px 30px, 25px 40px;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
}

.cloud-loader::after {
  content: '';
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-color: #f8b601 transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
