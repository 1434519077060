@import '../../variable';


.select-coffee-popup.coffee-modal {
  .font-sm {
    font-size: 0.8rem;
    font-weight: 300;
  }

  .container-border-top {
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
  }

  .cover-image-container {
    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .title {
      font-size: 1.3rem !important;
    }
  }

  .body {
    flex: 1;
    overflow: hidden;

    .react-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;

      .react-tabs__tab-list {
        border: none;
        margin: 0;

        .react-tabs__tab {
          border: none;
          padding: 10px 0;
          max-width: 300px;
          width: 50%;
          text-align: center;
          position: relative;
          opacity: 0.3;

          &:before {
            content: '';
            opacity: 0.6;
            transform: scale(0);
            transform-origin: 50% 50%;
          }
        }

        .react-tabs__tab--selected {
          position: relative;
          opacity: 1;

          &:before {
            position: absolute;
            left: 0;
            right: 0;
            height: 3px;
            background: red;
            bottom: 0;
            transition: 0.2s;
            transform: scale(1);
          }
        }
      }

      .react-tabs__tab-panel {
        flex: 1;
        overflow-y: auto;
        padding: 0 15px;

        .tab-content {
          .svg-inline--fa.fa-check {
            font-size: 0;
            transform-origin: 50% 50%;
            transition: 0.3s cubic-bezier(0, 0, 0.2, 2);
          }

          .checked {
            font-size: 1.1rem !important;
          }

          > .addOn-container:not(:last-child) {
            margin-bottom: 15px;
          }

          > .addOn-container:first-child {
            margin-top: 15px;
          }

          .addOn-container {
            .addOn-category {
              position: relative;

              .title {
                color: $color-primary;
                font-size: 1.2rem;
                font-weight: 600;
              }

              .selected-addon-counter {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 0.5em;
              }

              .hide-button {
                color: #000;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
              }
            }

            .item {
              padding-left: 30px;
            }

            .category-order-tips {
              margin-top: 3px;

              > div {
                font-size: 0.7rem;
                font-weight: 300;
                font-style: italic;
              }
            }

            .item-disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }

          > div:not(.addOn-container),
          .item {
            border-bottom: 1px solid #e3e3e3;
            margin-left: -15px;
            margin-right: -15px;
            margin-top: 12px;
            padding: 0 40px 12px 15px;
            position: relative;
          }

          .fa-check {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .other-request {
          margin-left: -15px;
          margin-right: -15px;
          margin-top: 12px;
          padding: 0 40px 12px 15px;
          border-bottom: 1px solid #e3e3e3;

          input {
            border: none;
            width: 100%;
            outline: none !important;
          }
        }
      }
    }
  }

  .footer {
    position: relative;

    .min-order {
      position: absolute;
      height: 0;
      text-align: center;
      background: #fffbe6;
      color: rgba(0, 0, 0, 0.65);
      transition: 0.15s linear;
      left: 0;
      right: 0;
      top: 0;
      transform: translateY(-100%);
      overflow: hidden;
      font-weight: 600;
      display: flex;
      align-items: center;
      padding: 0 15px;

      svg {
        border: 1px solid rgba(0, 0, 0, 0.65);
        border-radius: 50%;
        margin-right: 10px;
        font-size: 0.6em;
        color: rgba(0, 0, 0, 0.65);
        padding: 0.35rem 0.5rem;
      }

      div {
        flex: 1;
        text-align: left;
        font-size: 0.8em;
      }
    }

    .invalid-min-order {
      height: auto;
      overflow: auto;
      padding: 10px 15px;
      border-top: 1px solid #ffe58f;

      span {
        font-weight: bold;
        color: $color-primary;
      }
    }
  }

  .footer-btn-list {
    display: flex;
    justify-content: flex-end;

    .btn {
      padding: 8px 5px;
      min-width: 35%;
      text-align: center;
      border: 0.5px solid $color-primary;
      margin-left: 10px;
      border-radius: 4px;
    }

    svg {
      margin-right: 5px;
      font-size: 1em;
    }

    .btn-submit {
      background: $color-primary;
      color: white;
    }

    .btn-cancel {
      color: $color-primary;
    }
  }

  .footer-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      font-size: 1.2em;
      font-weight: bold;
    }

    .quatity-container {
      display: flex;
      align-items: center;

      .fa-plus {
        background: $color-primary;
        color: white;
      }

      .fa-minus {
        background: white;
        color: $color-primary;
      }

      .svg-inline--fa {
        border-radius: 50%;
        padding: 0.4em 0.44em;
        font-size: 0.7em;
        border: 0.5px solid $color-primary;
      }

      .text {
        color: $color-primary;
        margin: 0 10px;
      }
    }
  }
}