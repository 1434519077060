.address-selection-page {
  .deep-shadow {
    box-shadow: 5px 5px 5px gray;
  }
  .map-marker-container {
    top: 50%;
    left: 50%;
    z-index: 1;
    position: absolute;
    transform: translate(-50%, -50%);

    .map-marker {
      transform: translate(0, -50%);
    }
  }

  .autocomplete-input {
    z-index: 1;
    position: absolute;
    top: 5%;
    left: 10%;
    right: 10%;

    height: 50px;
  }

  .continue-btn {
    z-index: 1;
    bottom: 5%;
    left: 10%;
    right: 10%;
    position: absolute;
  }
}
.pac-container {
  left: 50px !important;
}

@media screen and (max-width: 400px){
  .custom-pac-container{
    width: 250px !important;
    top: 70px !important;
  }
}

.custom-pac-container{
  position: fixed;
  top: 90px;
  background-color: white;
  width: 300px;
  list-style-type: none;
  padding:unset;

  li:not(:last-child){
    border-bottom: 1px solid lightgrey;
  }

  li{
    display: flex;
    padding: 10px;
    align-items: center;

    img{
      height: 20px;
      width: 20px;
      padding-right: 10px;
    }
  }
}
