$primary-color: #f8b601; //#88AFD6;
$primary-background-color: #f7f7f7;

.thankyou-page {
  position: relative;
  background-color: $primary-background-color;
  height: 100%;
  padding-top: 25px;
  font-size: 1.5rem;

  :first-child {
    font-size: 2.5rem;

    text-transform: uppercase;
    font-weight: bold;
  }

  > * {
    padding: 15px;
  }

  .btn {
    color: #ffffff;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 1.5rem;
    text-align: center;
    background-color: $primary-color;
  }
}
