$luckin-blue: #88afd6;
$aliment-orange: #f8b601;

$color-primary: $aliment-orange;

$primary-background-color: #f7f7f7;
$item-background-color: #ffffff;
$dark-blue-background-color: #101f30;

//PARTNER COLOR
$boost-color: #f03d3d;
$wechat-color: #19c800;
$tng-color: #0a59bd;
$grab-color: #14a74c;

$aliment-bg: #141c26;
$aliment-border: #1a222e;

$normal-font-size: 0.9rem;
$small-font-size: 0.5rem;
$large-font-size: 1.3rem;
$action-container-font-size: 0.8rem;

$body-font-size: 14px;
$lg-screen: 1000px;
