.lds-facebook {
  display: flex;
  position: relative;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;

  div {
    width: 8px;
    animation: lds-facebook 2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    animation-delay: -0.6s;
  }

  div:nth-child(2) {
    margin: 0 5px;
    animation-delay: -0.3s;
  }
}
