@import '../../variable';

.landing-page-button {
  font-size: 14px !important;
  max-height: 50px;
  min-width: 120px;
  background-color: $aliment-orange;

  img {
    height: 35px;
  }
}
