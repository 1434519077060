@import '../../variable';

.history-page {
  .blink_me {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .equal-size {
    display: flex;

    > * {
      flex: 1;
      align-self: flex-end;

      &:first-child {
        text-align: end;
      }

      &:last-child {
        text-align: start;
      }
    }
  }

  .pickup-no {
    color: $color-primary;
  }

  .user-container {
    max-height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    position: relative;
    background: url('/image/user-detail-background.png');
    background-position: center;
    background-size: cover;

    &:before {
      content: '';
      padding-bottom: 70%;
      display: block;
      background: black;
    }

    .fa-envelope,
    .top {
      position: absolute;
      top: 20px;
    }

    .fa-envelope {
      right: 20px;
      font-size: 1.4rem;
    }

    .title {
      font-weight: bold;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.2rem;
    }

    .user-detail {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translate(0%, -50%);

      .user-detail-row {
        display: flex;
        align-items: center;
        justify-content: center;

        .profile-pic {
          border-radius: 50%;
          width: 80px;
          margin-right: 10px;
        }

        .detail {
          text-align: center;
          font-weight: 600;

          .name {
            font-size: 1.3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(80vw - 120px);
            min-width: 150px;
          }

          .point {
            font-size: 0.8rem;
            margin-top: 8px;
          }
        }
      }
    }

    .id-row {
      position: absolute;
      bottom: 15%;
      text-align: center;
      font-size: 0.8rem;
      font-weight: 300;
      padding-top: 20px;
    }
  }

  .no-result {
    //Put in middle
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 15px 0;
    font-weight: 300;
    text-align: center;
  }

  .order-history {
    .title {
      text-align: center;
      position: sticky;
      border-bottom: 0.5px solid #e3e3e3;
      padding: 15px 0;
      top: 0;
      background: white;
      z-index: 2;
      font-size: 1.1rem;
    }

    .order-history-list {
      > .order-row:not(:last-child) {
        border-bottom: 0.5px solid #e3e3e3;
      }

      .order-pending,
      .order-failed {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 4px solid;
        }

        .pending,
        .failed {
          font-size: 0.8rem;
          margin-left: 10px;
          font-weight: bold;
          text-transform: capitalize;
        }
      }

      .order-pending {
        &:before {
          border-left-color: $color-primary;
        }
      }

      .order-failed {
        &:before {
          border-left-color: red;
        }
      }

      .order-row {
        position: relative;
        padding: 10px 40px 10px 20px;

        span {
          vertical-align: middle;
        }
      }

      .fa-chevron-right {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-primary;
        font-size: 1.8rem;
      }

      .font-weight-light {
        font-weight: 300;
      }
    }
  }

  .modal-order-container {
    .modal-body {
      .modal-order {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .modal-order-container {
          flex: 1;
          overflow: auto;
        }

        .modal-order-detail {
          background: black;
          color: white;
          padding: 20px 0 40px;
          text-align: center;

          .font-sm {
            font-size: 0.8rem;
            font-weight: 300;
            line-height: 1.6;
          }

          .font-lg {
            font-size: 1.5rem;
            font-weight: bold;
          }

          .currency {
            vertical-align: baseline;
            margin-right: 5px;
          }

          .pts {
            vertical-align: baseline;
            margin-left: 5px;
          }

          .date-margin {
            margin-top: 4px;
          }

          .pending,
          .failed {
            font-size: 0.8rem;
            text-transform: capitalize;
          }

          .pending {
            color: $color-primary;
          }

          .failed {
            color: red;
          }

          > div:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .order-history {
          .order-row {
            border-bottom: 0.5px solid #e3e3e3;
          }
        }

        .btn-receipt {
          background: $color-primary;
          text-align: center;
          padding: 15px 0;
        }
      }
    }
  }

  .modal-email-container {
    .modal-expand {
      z-index: 999;
    }

    .modal-body {
      height: unset;

      .confirm-button.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .email-container {
        padding: 20px;

        > *:not(:last-child) {
          margin-bottom: 10px;
        }

        input {
          width: 100%;
          border: none;
          border-bottom: 0.5px solid #e3e3e3;
          outline: none;
          padding-bottom: 10px;
        }

        .title {
          font-size: 1.2rem;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

  .modal-body {
    padding: 0;

    .fa-times {
      background: black;
    }
  }

  .modal-success-email-sent {
    text-align: center;

    .modal-body {
      height: unset;

      .content-container {
        padding: 20px;

        > div:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .title {
        font-weight: bold;
      }

      .email-loading {
        text-align: center;
        padding: 25px 0;

        svg {
          font-size: 1.5rem;
          color: lightgray;
        }
      }
    }
  }
}
